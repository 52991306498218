function App() {
  
  const html = `<head>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <title>Exit/Skies</title>
  </head>
  
  <body class="back-row-toggle splat-toggle">
    <div class="rain front-row"></div>
    <div class="rain back-row"></div>
    <div class="toggles">
      <div id="tvz">
           <div class="television">
    <div class="television__top">
      <div class="television__antenna television__antenna--left"></div>
      <div class="television__antenna television__antenna--right"></div>
      <div class="television__antenna__base"></div>
    </div><!-- television__top -->
    <div class="television__center">
      <div class="television__screen">
        <iframe src="https://www.youtube.com/embed/VQOTCsBxYPo?autoplay=1" frameborder="0" allowfullscreen allowautoplay allow='autoplay'></iframe>
      </div><!-- television__screen -->
      <div class="television__channels-wrapper">
        <ul class="television__channels">
          <li class="television__channel"><a href="https://www.youtube.com/embed/VQOTCsBxYPo?autoplay=1" title="Madame Roxy"></a></li>
          <li class="television__channel"><a href="https://www.youtube.com/embed/lETs6iHqy1Y?autoplay=1" title="The Rain March"></a></li>
          <li class="television__channel"><a href="https://www.youtube.com/embed/ShveSjsRmt0?autoplay=1" title="Sweatbox"></a></li>
        </ul>
      </div><!-- television__channels-wrapper -->
    </div><!-- television__center -->
    <div class="television__base">
      <div class="television__foot television__foot--left"></div>
      <div class="television__foot television__foot--right"></div>
    </div><!-- television__base -->
  </div><!-- .television -->
  
  <script>
    var buttons = document.querySelectorAll('.television__channel a');
  
  for(var i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener('click', function(e) {
      document.querySelector('.television__screen iframe').src = this.href;
      e.preventDefault();
    });
  }
  
  var makeItRain = function() {
    //clear out everything
    $('.rain').empty();
  
    var increment = 0;
    var drops = "";
    var backDrops = "";
  
    while (increment < 100) {
      //couple random numbers to use for various randomizations
      //random number between 98 and 1
      var randoHundo = (Math.floor(Math.random() * (98 - 1 + 1) + 1));
      //random number between 5 and 2
      var randoFiver = (Math.floor(Math.random() * (5 - 2 + 1) + 2));
      //increment
      increment += randoFiver;
      //add in a new raindrop with various randomizations to certain CSS properties
      drops += '<div class="drop" style="left: ' + increment + '%; bottom: ' + (randoFiver + randoFiver - 1 + 100) + '%; animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"><div class="stem" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div><div class="splat" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div></div>';
      backDrops += '<div class="drop" style="right: ' + increment + '%; bottom: ' + (randoFiver + randoFiver - 1 + 100) + '%; animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"><div class="stem" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div><div class="splat" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div></div>';
    }
  
    $('.rain.front-row').append(drops);
    $('.rain.back-row').append(backDrops);
  }
  
  $('.splat-toggle.toggle').on('click', function() {
    $('body').toggleClass('splat-toggle');
    $('.splat-toggle.toggle').toggleClass('active');
    makeItRain();
  });
  
  $('.back-row-toggle.toggle').on('click', function() {
    $('body').toggleClass('back-row-toggle');
    $('.back-row-toggle.toggle').toggleClass('active');
    makeItRain();
  });
  
  $('.single-toggle.toggle').on('click', function() {
    $('body').toggleClass('single-toggle');
    $('.single-toggle.toggle').toggleClass('active');
    makeItRain();
  });
  
  makeItRain();
  </script>
  
      </div>
  
  
      <style>
      html {
    height: 100%;
  }
  
  body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    background: linear-gradient(to bottom, #202020, #111119);
  }
  
  .rain {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .rain.back-row {
    display: none;
    z-index: 1;
    bottom: 60px;
    opacity: 0.5;
  }
  
  body.back-row-toggle .rain.back-row {
    display: block;
  }
  
  .drop {
    position: absolute;
    bottom: 100%;
    width: 15px;
    height: 120px;
    pointer-events: none;
    animation: drop 0.5s linear infinite;
  }
  
  @keyframes drop {
    0% {
      transform: translateY(0vh);
    }
    75% {
      transform: translateY(90vh);
    }
    100% {
      transform: translateY(90vh);
    }
  }
  
  .stem {
    width: 1px;
    height: 60%;
    margin-left: 7px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
    animation: stem 0.5s linear infinite;
  }
  
  @keyframes stem {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  .splat {
    width: 15px;
    height: 10px;
    border-top: 2px dotted rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    opacity: 1;
    transform: scale(0);
    animation: splat 0.5s linear infinite;
    display: none;
  }
  
  body.splat-toggle .splat {
    display: block;
  }
  
  @keyframes splat {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    80% {
      opacity: 1;
      transform: scale(0);
    }
    90% {
      opacity: 0.5;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }
  
  .toggles {
    top: 0;
    left: 0;
    z-index: 3;
  }
  
  .toggle {
    position: absolute;
    left: 20px;
    width: 50px;
    height: 50px;
    line-height: 51px;
    box-sizing: border-box;
    text-align: center;
    font-family: sans-serif;
    font-size: 10px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .toggle:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
  
  .toggle:active {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .toggle.active {
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  .splat-toggle {
    top: 20px;
  }
  
  .back-row-toggle {
    top: 90px;
    line-height: 12px;
    padding-top: 14px;
  }
  
  .single-toggle {
    top: 160px;
  }
  
  body.single-toggle .drop {
    display: none;
  }
  
  body.single-toggle .drop:nth-child(10) {
    display: block;
  }
  
  
  
  
  /*     TV */
  
  
  iframe {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 10px solid;
    border-radius: 32px;
  }
  
  .television {
    width: 750px;
    margin:  auto;
  }
  
  .television__top {
    width: 40%;
    margin: auto;
    position: relative;
  }
  
  .television__antenna {
    width: 5px;
    height: 100px;
    background-color: #3b3733;
    margin-bottom: -10px;
  }
  
  .television__antenna--left {
    transform: rotate(-30deg);
    float: left;
  }
  
  .television__antenna--right {
    transform: rotate(30deg);
    float: right;
  }
  
  .television__antenna__base {
    height: 20px;
    background-color: black;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
    margin-bottom: 10px;
    clear: both;
    position: relative;
    z-index: 2;
  }
  
  .television__center {
     position: relative;
     z-index: 2;
     width: 750px;
     height: 500px;
     background-color: grey;
     border: solid 3px black;
     box-shadow: -10px -10px black;
     border-radius: 48px;
     margin: 0 auto;
     padding: 10px 15px;
  }
  
  .television__screen {
     width: 675px;
     height: 500px;
     background-color: #eed5b6;
     border-radius: 48px;
     float: left;
  }
  
  .television__channels-wrapper {
     width: 49px;
     height: 190px;
     background-color: #383838;
     border-radius: 48px;
     float: right;
     padding-right: 18px;
  }
  
  .television__channels {
     padding: 0;
     padding-left:11px;
  }
  
  .television__channel {
     list-style-type: none;
     background-color: black;
     width: 35px;
     height: 35px;
     border-radius: 50%;
     margin: 5px;
     float: left;
     position: relative;
     padding-top:8px;
  }
  
  .television__channel a {
     display: block;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     background-color: lightgrey;
     width: 28px;
     height: 28px;
     border-radius: 50%;
  }
  
  .television__channel a:active {
     box-shadow: inset rgba(255,255,255,0.6) 0 2px 2px,
                 inset rgba(0,0,0,0.15) 0 -2px 5px, /* inner shadow */
            rgba(100,100,100,0.6) 0 2px 1px,
                 rgba(100,100,100,0.6) 0 2px 1px; /* color border */
  }
  
  .television__base {
     width: 60%;
     margin: auto;
  }
  
  .television__base:after {
     clear: both;
     content: "";
     display: table;
  }
  
  .television__foot {
     width: 20px;
     height: 70px;
    margin-top: -20px;
     background-color: grey;
     border: solid 3px black;
     border-radius: 48px;
  }
  
  .television__foot--left {
     transform: rotate(30deg);
     float: left;
  }
  
  .television__foot--right {
     transform: rotate(-30deg);
     float: right;
  }
  
  
  .exitSkies{
        color: white;
      font-family: monospace;
      margin: auto;
  }
  
  </style>
  </body>`;

return <div dangerouslySetInnerHTML={{__html: html}}></div>
}

export default App;
